import moment from 'moment';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid, MenuItem, Typography, Box, Paper, Stack,
  useMediaQuery, IconButton,
} from '@mui/material';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { DatePicker, TimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import Modal from '../components/Modal';
import Form, { useForm } from '../components/useForm';
import useSiteSettings from '../hooks/useSiteSettings';
import { useIntakeData } from '../context/IntakeDataContext';
import { useApiClient } from '../context/ApiClientContext';
import validatePhoneNumber from '../services/validatePhoneNumber';
import basicServiceHandler from '../services/basicServiceHandler';
import { axiosErrorToUserMessage } from '../services/axios';
import ConfirmModal from './ConfirmModal';
import { Controls } from '../components/controls/Controls';
import PatientContactList from './integrations/PointClickCare/PatientContactList';

const initialFieldValues = {
  patientId: '',
  phoneNumber: '',
  moduleId: undefined,
  appointmentDate: null,
  appointmentTime: null,
};

const INTAKE_FRONTEND_URL = process.env.REACT_APP_INTAKE_FRONTEND_URL;

export default function SendIntakeModal(props) {
  const {
    modalVisible,
    integrationType,
    value,
    patient,
    handleClose,
    setSuccessMessage,
    setErrorMessage,
    showAppointmentField,
  } = props;

  const apiClient = useApiClient();

  const {
    values,
    setValues,
    errors,
    setErrors,
    handleInputChange,
  } = useForm(initialFieldValues);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [confirmPhoneNumberVisible, setConfirmPhoneNumberVisible] = useState(false);
  const [phoneNumberConfirmed, setPhoneNumberConfirmed] = useState(false);
  const [smsButtonLoading, setSmsButtonLoading] = useState(false);
  const [editPhoneNumber, setEditPhoneNumber] = useState(false);
  const [targetHxId, setTargetHxId] = useState(null);
  const [buttonAction, setButtonAction] = useState(null);
  const [intakeUrlButtonLoading, setIntakeUrlButtonLoading] = useState(false);
  const [intakeUrl, setIntakeUrl] = useState(null);
  const [intakePlans, setIntakePlans] = useState(null);
  const isMobile = useMediaQuery('(max-width:425px)');

  const { activeSiteId, accessibleSiteInfos } = useSiteSettings();

  function setIdentifiableData() {
    const newValues = {
      ...values,
      phoneNumber: value,
      patientId: patient.patientId.toString(),
    };
    setValues(newValues);
  }

  const {
    fetchIntakeData,
  } = useIntakeData();

  useEffect(() => {
    const fetchintakePlan = async (intakePlanId) => {
      const intakePlan = await basicServiceHandler(
        () => apiClient.getIntakePlan(intakePlanId),
        (response) => response.data,
        (error) => {
          setErrorMessage(
            axiosErrorToUserMessage(error)
            || 'An error occurred when fetching the report.',
          );
        },
      );
      return intakePlan;
    };

    async function fetchIntakePlans() {
      const activeSiteInfo = accessibleSiteInfos.find(
        (site) => site.id === activeSiteId,
      );
      const intakePlansSetting = activeSiteInfo?.settings.find((setting) => setting.name === 'intakePlans');
      if (!intakePlansSetting) {
        setErrorMessage('No intake plans were found.');
        return;
      }

      let intakePlansList = [];
      if (intakePlansSetting.value === '*') {
        try {
          intakePlansList = (await apiClient.getIntakePlans()).data;
        } catch (error) {
          setErrorMessage(axiosErrorToUserMessage(error) || 'An error occurred when fetching intake plans.');
          return;
        }
      } else {
        const intakePlanIds = intakePlansSetting.value.split(',');

        const fetchedintakePlans = await Promise.allSettled(
          intakePlanIds.map((id) => fetchintakePlan(id)),
        );
        intakePlansList = fetchedintakePlans.filter((intakePlan) => intakePlan.status === 'fulfilled').map((intakePlan) => intakePlan.value);
        const intakePlanErrors = fetchedintakePlans.filter((intakePlan) => intakePlan.status === 'rejected').map((intakePlan) => intakePlan.reason.message);

        intakePlanErrors.forEach((error) => (
          setErrorMessage(axiosErrorToUserMessage(error) || 'An error occurred when fetching intake plans.')
        ));
      }
      setIntakePlans(intakePlansList);
    }

    if (modalVisible) {
      setIntakePlans(null);
      fetchIntakePlans();
    }
    if (patient) {
      setIdentifiableData();
    }
  }, [activeSiteId, modalVisible, accessibleSiteInfos]);

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(intakeUrl);
    setSuccessMessage('Intake URL copied to clipboard');
  };

  const createIntake = async () => {
    const intakePlanId = values.intakePlanId || intakePlans[0].id;

    // Combine appointmentDate and appointmentTime into a single appointmentDate
    const combinedAppointmentDate = values.appointmentDate && values.appointmentTime
      ? moment(values.appointmentDate)
        .set({
          hour: values.appointmentTime.hour(),
          minute: values.appointmentTime.minute(),
        })
        .format()
      : null;

    const identifiableData = {
      patientId: values.patientId,
      integrationType,
      patientName: values.patientName,
      appointmentDate: combinedAppointmentDate,
      phoneNumber: values.phoneNumber.replace(/[^\d]/g, ''),
    };

    const { mkey } = (await apiClient.createIntake({
      intakePlanId,
      siteId: activeSiteId,
      identifiableData,
    })).data;

    return mkey;
  };

  const findPiiUsingPatientId = async () => {
    const pid = values.patientId;
    const res = await apiClient.checkIfPatientUniqueToSite(pid, activeSiteId);
    return res.data;
  };

  const validatePatientId = () => {
    const activeSiteInfo = accessibleSiteInfos.find(
      (site) => site.id === activeSiteId,
    );
    const integrationTypeSetting = activeSiteInfo?.settings.find((setting) => setting.name === 'pushToEndpointIntegrationType');
    const silverberryEnabled = integrationTypeSetting && integrationTypeSetting.value === 'silverberry';
    const validChars = silverberryEnabled ? /^[A-Za-z0-9-]+$/ : /^[A-Za-z0-9 -]+$/; // don't allow spaces for silverberry patientId
    const errorMessage = silverberryEnabled ? 'Invalid character(s). Please only use: a-z, A-Z, 0-9, or -. Do not use spaces.'
      : 'Invalid character(s). Please only use: a-z, A-Z, 0-9, space, or -.';
    if (!values.patientId) {
      return 'Patient ID is required';
    } if (!values.patientId.match(validChars)) {
      return errorMessage;
    }
    return '';
  };

  const validate = async (action) => {
    const errorMsg = {
      patientId: validatePatientId(),
      intakePlanId: intakePlans.length === 1 || values.intakePlanId ? '' : 'Please select an intake plan',
      phoneNumber: action === 'sendSMS' ? await validatePhoneNumber(values.phoneNumber, apiClient, true) : '',
      appointmentDate: action === 'sendSMS' && showAppointmentField && !values.appointmentDate ? 'Appointment date is required' : '',
      appointmentTime: action === 'sendSMS' && showAppointmentField && !values.appointmentTime ? 'Appointment time is required' : '',
    };

    setErrors({ ...errorMsg });
    return Object.values(errorMsg).every((item) => item === '');
  };

  const getIntakeUrl = (mkey) => {
    if (mkey) {
      const url = `${INTAKE_FRONTEND_URL}/?mkey=${mkey}`;
      setIntakeUrl(url);
      setSuccessMessage('Intake successfully created. Use the generated URL to complete the intake.');
    } else {
      setErrorMessage('Unable to create intake.');
    }
  };

  const sendSMS = async (cleanPhoneNumber, mkey) => {
    try {
      const result = (await apiClient.sendSMS(cleanPhoneNumber, mkey)).data;
      const { success } = result;
      if (success === true) {
        setSuccessMessage('Intake sent to patient.');
      } else {
        const message = result.message || 'An intake was created, but the SMS failed to send. The phone number may be invalid.';
        setErrorMessage(message);
      }
    } catch (error) {
      setErrorMessage(axiosErrorToUserMessage(error) || 'An intake was created, but an error occurred when sending SMS.');
    }
  };

  const createAndSendIntake = async (setLoading, action) => {
    try {
      const cleanPhoneNumber = values.phoneNumber.replace(/[^\d]/g, '');
      const mkey = await createIntake();
      if (action === 'sendSMS') {
        setIntakeUrl(null);
        await sendSMS(cleanPhoneNumber, mkey);
      } else {
        getIntakeUrl(mkey);
      }
    } catch (error) {
      setErrorMessage(axiosErrorToUserMessage(error) || 'An error occurred when creating an intake');
    } finally {
      fetchIntakeData();
      setLoading(false);
    }
  };

  const handleSubmit = async (e, action) => {
    e.preventDefault();
    if (await validate(action)) {
      setButtonAction(action);
      const setLoading = action === 'sendSMS' ? setSmsButtonLoading : setIntakeUrlButtonLoading;
      setLoading(true);
      const pii = await findPiiUsingPatientId();
      if (pii) {
        setTargetHxId(pii.hxId);
      }

      if (integrationType === 'accuro' && !phoneNumberConfirmed) {
        setConfirmPhoneNumberVisible(true);
      } else if (pii) {
        setConfirmModalVisible(true);
      } else {
        createAndSendIntake(setLoading, action);
      }
    }
    if (errors.phoneNumber) {
      setEditPhoneNumber(true);
    }
  };

  const handleCloseConfirmModal = (modal, action) => {
    setErrors({});
    setSmsButtonLoading(false);
    setIntakeUrlButtonLoading(false);
    const setModalVisible = modal === 'phone' ? setConfirmPhoneNumberVisible : setConfirmModalVisible;
    setModalVisible(false);
    if (action === 'reject') {
      setEditPhoneNumber(true);
    }
  };

  const handleConfirmSubmit = async (e) => {
    e.preventDefault();
    // deactivate previous intake and delete pii
    if (targetHxId) {
      await apiClient.deleteIntakeByHxId(activeSiteId, targetHxId);
      setTargetHxId(null);
    }
    const setLoading = buttonAction === 'sendSMS' ? setSmsButtonLoading : setIntakeUrlButtonLoading;
    createAndSendIntake(setLoading, buttonAction);
    setConfirmPhoneNumberVisible(false);
    setConfirmModalVisible(false);
    setPhoneNumberConfirmed(false);
  };

  const handleCloseModal = () => {
    handleClose();
    setValues(initialFieldValues);
    setErrors({});
    setIntakeUrl(null);
    setPhoneNumberConfirmed(false);
  };

  const handleDateChange = (newValue) => {
    const today = moment().startOf('day');

    if (newValue && moment(newValue, moment.ISO_8601, true).isValid()) {
      const selectedDate = moment(newValue).startOf('day');

      if (selectedDate.isBefore(today)) {
        setErrors({ ...errors, appointmentDate: 'The date cannot be in the past.' });
      } else {
        setValues({ ...values, appointmentDate: newValue });
        setErrors({ ...errors, appointmentDate: '' });
      }
    } else {
      setErrors({ ...errors, appointmentDate: 'Invalid date format' });
    }
  };

  const handleTimeChange = (newValue) => {
    const currentDateTime = moment();
    const selectedDate = moment(values.appointmentDate).startOf('day');

    if (newValue && moment(newValue, moment.ISO_8601, true).isValid()) {
      const selectedTime = moment(newValue, 'HH:mm');

      // If the selected date is today, check if the selected time is in the future
      if (selectedDate.isSame(currentDateTime, 'day') && selectedTime.isBefore(currentDateTime)) {
        setErrors({ ...errors, appointmentTime: 'The time cannot be in the past.' });
      } else {
        setValues({ ...values, appointmentTime: newValue });
        setErrors({ ...errors, appointmentTime: '' });
      }
    } else {
      setErrors({ ...errors, appointmentTime: 'Invalid time format' });
    }
  };

  return (
    <Modal
      isOpen={modalVisible}
      title="New Intake"
      handleClose={handleCloseModal}
    >
      <ConfirmModal
        handleCloseConfirmModal={() => handleCloseConfirmModal('phone')}
        handleRejectConfirm={() => handleCloseConfirmModal('phone', 'reject')}
        confirmDialogVisible={confirmPhoneNumberVisible}
        handleConfirmSubmit={targetHxId ? setConfirmModalVisible : handleConfirmSubmit}
        modalText={`Is this phone number correct? ${values.phoneNumber}`}
        title="Please confirm phone number"
        confirmText="Confirm"
        cancelText="Edit"
      />
      <ConfirmModal
        handleCloseConfirmModal={() => handleCloseConfirmModal()}
        handleRejectConfirm={() => handleCloseConfirmModal()}
        confirmDialogVisible={confirmModalVisible}
        handleConfirmSubmit={handleConfirmSubmit}
        modalText="Patient ID already exist, would you like to send again? It will delete the old intake."
        title="Confirm"
        confirmText="Yes"
        cancelText="No"
      />
      <Form groupsx={{
        '& .MuiFormControl-root': {
          width: '100%',
          margin: '8px 0',
        },
      }}
      >
        <Grid container>
          <Grid item xs={12}>
            {intakePlans && intakePlans.length > 1 && (
            <Controls.Input
              label="Select an Intake Plan"
              name="intakePlanId"
              value={values.intakePlanId ?? ''}
              onChange={handleInputChange}
              error={errors.intakePlanId}
              data-testid="intakePlanInput"
              select
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '16px',
                },
              }}
            >
              {intakePlans.map((intakePlan, index) => (
                <MenuItem key={intakePlan.id} value={intakePlan.id} data-testid={`intakePlan-${index}`}>
                  {intakePlan.customerDisplayName}
                </MenuItem>
              ))}
            </Controls.Input>
            )}
            {integrationType === 'pointClickCare' && patient ? (
              <PatientContactList
                setErrorMessage={setErrorMessage}
                siteId={activeSiteId}
                patientId={patient.patientId}
                handleInputChange={handleInputChange}
                errors={errors.phoneNumber}
              />
            ) : (
              <>
                <Controls.Input
                  label="Patient ID"
                  name="patientId"
                  value={values.patientId}
                  error={errors.patientId}
                  onChange={handleInputChange}
                  data-testid="patientIDFormInput"
                  disabled={['accuro', 'accuroAfterAmbientAi', 'pointClickCare'].includes(integrationType)}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '16px',
                    },
                  }}
                />
                <Controls.Input
                  label="Phone Number"
                  name="phoneNumber"
                  value={values.phoneNumber}
                  error={errors.phoneNumber}
                  onChange={handleInputChange}
                  data-testid="patientNoFormInput"
                  disabled={(integrationType === 'accuro') ? !editPhoneNumber : false}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '16px',
                    },
                  }}
                />
              </>
            )}
            {showAppointmentField && (
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Appointment Date"
                  value={values.appointmentDate}
                  onChange={handleDateChange}
                  disablePast
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '16px',
                    },
                  }}
                  slotProps={{
                    textField: {
                      helperText: errors.appointmentDate,
                      error: !!errors.appointmentDate,
                    },
                  }}
                />
                <TimePicker
                  label="Appointment Time"
                  value={values.appointmentTime}
                  onChange={handleTimeChange}
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '16px',
                    },
                  }}
                  slotProps={{
                    textField: {
                      helperText: errors.appointmentTime,
                      error: !!errors.appointmentTime,
                    },
                  }}
                />
              </LocalizationProvider>
            )}
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: isMobile ? 'column' : 'row',
                width: '100%',
                my: 1,
              }}
            >
              <Controls.Button
                variant="outlined"
                onClick={(e) => handleSubmit(e, 'sendSMS')}
                loading={smsButtonLoading}
                disabled={intakeUrlButtonLoading}
                text="Send SMS"
                data-testid="sendSMSButton"
              />
              <Controls.Button
                variant="contained"
                sx={{ margin: isMobile ? '8px 0' : '0 0 0 8px' }}
                name="intakeUrl"
                onClick={(e) => handleSubmit(e, 'getIntakeUrl')}
                loading={intakeUrlButtonLoading}
                disabled={smsButtonLoading}
                text="Get Intake URL"
                data-testid="getIntakeUrlButton"
              />
            </Box>
          </Grid>
        </Grid>
      </Form>
      {intakeUrl && (
        <Paper variant="outlined" sx={{ marginTop: '16px' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" padding={1}>
            <Typography>{intakeUrl}</Typography>
            <IconButton
              onClick={handleCopyToClipboard}
              size="small"
            >
              <ContentCopyRoundedIcon />
            </IconButton>
          </Stack>
        </Paper>
      )}
    </Modal>
  );
}

SendIntakeModal.propTypes = {
  modalVisible: PropTypes.bool.isRequired,
  integrationType: PropTypes.string,
  value: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  patient: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  setSuccessMessage: PropTypes.func.isRequired,
  setErrorMessage: PropTypes.func.isRequired,
  showAppointmentField: PropTypes.bool.isRequired,
};

SendIntakeModal.defaultProps = {
  value: '',
  integrationType: null,
  patient: null,
};
