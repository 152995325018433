import React from 'react';
import {
  Routes, Route,
} from 'react-router-dom';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { ApiClientProvider } from './context/ApiClientContext';
import FhxLogin from './login/FhxLogin';
import ResetPassword from './login/ResetPassword';
import Home from './home/home';
import Unauthorized from './components/pages/Unauthorized';
import RequireAuth from './components/pages/RequireAuth';
import PersistLogin from './login/PersistLogin';
import SiteFrontend from './site/SiteFrontendHome';
import SiteSettingsFrontend from './site/SiteSettingsFrontend';
import CustomerSettingsFrontend from './customers/CustomerSettingsFrontend';
import FhxSignUp from './onboard/FhxSignUp';
import FhxMfaSignUp from './onboard/FhxMfaSignUp';
import CustomerHome from './customers/CustomerHome';
import UserManagement from './site/Users/UserManagement';
import IntakeReviewPage from './content/IntakeInfoTable/IntakeReviewPage';
import CreateNewUserPage from './content/CreateNewUserPage';
import ChangePasswordPage from './content/ChangePasswordPage';
import IframePage from './components/pages/IframePage';
import customizeTheme from './Themes';
import FhxEmailMfaSetup from './onboard/FhxEmailMfaSetup';

function App() {
  const customTheme = customizeTheme();
  const theme = createTheme(customTheme);
  return (
    <ThemeProvider theme={theme}>
      <ApiClientProvider>
        <Routes>
          {/* public */}
          <Route path="/login" element={<FhxLogin />} />
          <Route path="/unauthorized" element={<Unauthorized />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />
          <Route path="/signup/:signupToken" element={<FhxSignUp />} />
          <Route path="/multi-factor-signup" element={<FhxMfaSignUp />} />
          <Route path="/email-mfa" element={<FhxEmailMfaSetup />} />

          {/* private */}
          <Route element={<PersistLogin />}>
            <Route element={<RequireAuth requiredPermissions={{ hxManager: ['Read'] }} />}>
              <Route path="/home" element={<Home />} />
              <Route path="/review/:mkey" element={<IntakeReviewPage />} />
              <Route path="/silverberryReview" element={<IframePage />} />
              <Route path="/user/changePassword" element={<ChangePasswordPage />} />
            </Route>
            {/* createUser */}
            <Route element={<RequireAuth requiredPermissions={{ hxManager: ['CreateUsersForOwnCustomers'] }} />}>
              <Route path="/user/create" element={<CreateNewUserPage />} />
            </Route>
            {/* customer frontend */}
            <Route element={<RequireAuth requiredPermissions={{ customer: ['Admin'] }} />}>
              <Route path="/customer" element={<CustomerHome />} />
              <Route path="/customer/:customerId" element={<CustomerSettingsFrontend />} />
            </Route>
            {/* site frontend */}
            <Route element={<RequireAuth requiredPermissions={{ customer: ['Admin', 'CustomerAdmin'] }} />}>
              <Route path="/site" element={<SiteFrontend />} />
              <Route path="/site/:siteId/:siteName/users" element={<UserManagement />} />
              <Route path="/site/:siteId" element={<SiteSettingsFrontend />} />
            </Route>
          </Route>
          {/* fallback */}
          <Route path="*" element={<FhxLogin />} />
        </Routes>
      </ApiClientProvider>
    </ThemeProvider>
  );
}

export default App;
